<template>
    <a-modal width="600px" centered v-model:open="isOpen" >
        <template #title>
            <span class="text-lg font-bold">{{ $t('Pos.PrintReceipt')}}</span>
        </template>
        <div >
            <span class="text-base" >{{ $t('Pos.printRceiptPlaceholder')}}</span>
            <div class="flex gap-2 mt-4">
            <button  class="flex w-full rounded-md py-2 text-lg items-center justify-center" 
            :class="selectedButton === 'productionPoint' ? 'text-white' : ''"
            :style="selectedButton === 'productionPoint'? { backgroundColor }: { border: `2px solid ${backgroundColor}`, color: backgroundColor, backgroundColor: 'transparent' }" 
            @click="print('productionPoint')">
            <span >{{$t('Pos.productionPoint')}}</span>
          
            </button>

            <button class="flex w-full rounded-md py-2 text-lg items-center justify-center" 
            :class="selectedButton === 'POS' ? 'text-white' : ''"
            :style="selectedButton === 'POS'? { backgroundColor }: { border: `2px solid ${backgroundColor}`, color: backgroundColor, backgroundColor: 'transparent' }" 
            @click="print('POS')">
                <span >{{$t('Pos.POS')}}</span>
            </button>

            <button class="flex w-full rounded-md py-2 text-lg items-center justify-center"
            :class="selectedButton === 'both' ? 'text-white' : ''"
            :style="selectedButton === 'both'? { backgroundColor }: { border: `2px solid ${backgroundColor}`, color: backgroundColor, backgroundColor: 'transparent' }" 
            @click="print('both')">
                <span>{{$t('Pos.Both')}}</span>
            </button>
            </div>
        </div>

        <template #footer>

        </template>
      
    </a-modal>  
</template>
<script lang="ts">
import { defineComponent, defineProps, defineEmits, onMounted, watch  } from "vue";

export default defineComponent({
  name: "LbrxPrintModal",
});
</script>
<script setup lang="ts">
import { ref } from "vue";
import { LoadingOutlined } from '@ant-design/icons-vue';

const props = defineProps<{
    open: boolean,
}>();

const emit = defineEmits<{
  (e: 'update:open', value: boolean): void
  (e: 'print', location: string): void
}>();
const selectedButton = ref<string>("");
const isOpen = ref(props.open);

watch(() => props.open, (newValue) => {
  isOpen.value = newValue;
});

watch(isOpen, (newValue) => {
  emit('update:open', newValue);
});



const print = (location:string) => {
    selectedButton.value = location;
    emit('print', location);
    selectedButton.value="";
};

const backgroundColor=ref('');

onMounted(()=>{
const bgColor =  localStorage.getItem('themeTemplate');

if(bgColor){
    backgroundColor.value =JSON.parse(bgColor).primaryColor;
}else{
    backgroundColor.value="#fc8019"
}


})

</script>

