<template>
  <div class="font-semibold flex flex-col flex-1 h-full">
    <div v-if="!cashierMode" class="bg-[#fff] flex justify-between items-center m-4 py-3 px-6 font-semibold rounded-md">
      <div class="flex flex-col items-start">
        <span class="text-xl">{{ $t('Pos.PayableAmount') }}</span>
        <span class="text-2xl font-bold text-confirmation">{{ formatAmount(sale.total, currencyCode, decimalPrecision) }}</span>
      </div>
      <div v-if="sale.customer" class="flex items-center gap-3">
        <img :src="sale.customer.photo ? sale.customer.photo : '/default_img.jpg'" alt=""
          class="rounded-full w-10 h-10">
        <div class="flex flex-col items-start">
          <span class="text-sm">{{ sale.customer.first_name + " " + sale.customer.last_name }}</span>
          <span class="text-sm opacity-45">#{{ sale.customer.id }}</span>
        </div>
        <button class="p-2 rounded-md bg-[#f4f4f4] h-fit">
          <iconTrash class="opacity-90" :size="16" :color="'#000'" />
        </button>
      </div>
      <div v-else>
        <span class="text-xl">{{ $t('Pos.Anonymous') }}</span>
      </div>
    </div>
    <div class="bg-white flex flex-col rounded-md h-full mx-4 mt-2 mb-4">
      <LbrxMenuUnderline v-if="!cashierMode" :filters="filters" :selectedFilter="selectedFilter"
        @selectFilter="selectFilter" :paymentMethodDefault="paymentMethodDefault" class="mb-3" />
      <div v-if="selectedFilter == 1" class="flex flex-col rounded-md justify-between flex-1 px-5 mt-2 gap-y-4">
        <div v-if="!noInput">
          <input v-if="pincodeMode" id="inputBar"
            class="py-2 text-center text-3xl border-2 w-full border-b-gray-300 font-extrabold" type="password"
            :value="amountToGive" style="-webkit-text-security: circle;">
          <input v-else-if="formattedInput" type="text" v-model="amountToGiveFixed" placeholder="0.000"
            class="w-full h-16 text-3xl rounded-sm text-right border-2 border-gray-300 focus:drop-shadow-lg focus:outline-none px-2 font-medium">
          <input v-else type="text" v-model="amountToGiveFormat" placeholder="0"
            class="w-full h-16 text-3xl rounded-sm text-right border-2 border-gray-300 focus:drop-shadow-lg focus:outline-none px-2 font-medium">
        </div>
        <div class="grid grid-cols-3 gap-4 font-semibold text-2xl py-2 h-full items-center">
          <div v-for="(item, index) in 9" :key="index" @click="addAmount(item)"
            class="bg-[#ebeef0] rounded-sm h-full flex items-center justify-center hover:drop-shadow-lg cursor-pointer">
            {{
              item }}</div>
          <div @click="addAmount('00')"
            class="bg-[#ebeef0] rounded-sm hover:drop-shadow-lg h-full flex items-center justify-center cursor-pointer min-w-fit">
            {{ "00" }}</div>
          <div @click="addAmount('0')"
            class="bg-[#ebeef0] rounded-sm hover:drop-shadow-lg h-full flex items-center justify-center cursor-pointer min-w-fit">
            {{ "0" }}</div>
          <div @click="addAmount(-1)"
            class="bg-[#ebeef0] rounded-sm hover:drop-shadow-lg h-full flex items-center justify-center cursor-pointer min-w-fit">
            <iconTrash class="opacity-90" :size="24" :color="'#000'" />
          </div>
          <div v-if="cashierMode" @click="confirmAction"
            class="bg-confirmation text-white rounded-sm hover:drop-shadow-lg h-full flex items-center justify-center cursor-pointer min-w-fit col-span-3">
            {{ $t('Pos.Confirm') }}</div>

           

          <div v-else @click="handleConfirmPayment" :disabled="(balance < 0 && sale.total) || loading"
            class="text-white rounded-sm hover:drop-shadow-lg h-full flex items-center justify-center cursor-pointer min-w-fit col-span-3"
            :class="balance >= 0 && !loading ? 'bg-[#09aa29]' : 'bg-[#bebebe]'">
            <div v-if="!loading" class="flex gap-3 items-center">
              {{ textButtonConfirm.length != 0 ? textButtonConfirm : $t('Pos.Confirm') }}
            </div>
            <LoadingOutlined v-else :style="{ fontSize: '32px' }" />
          </div>
        </div>
      </div>
      <div v-else class="flex flex-col rounded-md justify-between flex-1 px-5 gap-y-4">
        <div class="w-full text-left">
          <div class="flex flex-col gap-3 my-3">
            <span class="font-semibold">{{ $t('Pos.SelectPaymentM') }}</span>
            <div v-if="otherModes.length != 0" class="flex flex-col gap-2">
              <div v-for="(item, index) in otherModes" :key="index" @click="selectMethod(item)"
                class="bg-[#fff] w-full py-4 border-2 border-b-gray-300"
                :style="item.id == selectedMethod.id ? getSelectedStyle : ''">
                <span class="px-5">{{ item.method.name }}</span>
              </div>
            </div>
            <div v-else>
              <a-empty :description="$t('Pos.Cancel')" :image="simpleImage" />
            </div>
          </div>
          <div class="py-3" v-if="selectedMethod.id == -1">
            <span class="text-[#ea5455]">{{ $t('Pos.CustomerOffer') }}</span>
          </div>
          <input v-else-if="selectedMethod.id != -1 && otherModes.length != 0" id="numCode" @click="selectInput"
            @input="getCode" type="text" :placeholder="$t('Pos.EnterCode')"
            class="w-full text-3xl rounded-sm border-2 py-5 border-gray-300 px-2 font-medium">
        </div>
        <div v-if="selectedMethod.id == -1" @click="confirmPayment('offer')"
          class="bg-confirmation text-white rounded-md hover:drop-shadow-lg h-fit py-3 my-2 text-2xl flex items-center justify-center cursor-pointer min-w-fit col-span-3">
          {{ $t('Pos.MarkOffer') }}</div>
        <div v-else @click="confirmPayment('no_cash')"
          class="bg-confirmation text-white rounded-md hover:drop-shadow-lg h-fit py-3 my-2 text-2xl flex items-center justify-center cursor-pointer min-w-fit col-span-3">
          {{ $t('Pos.Confirm') }}</div>
      </div>
    </div>
    <LbrxKeyboardPopup :active="modalKeyboard.active" @closePopupKeyboard="closePopupKeyboard" />
  </div>
</template>

<script>
import iconTrash from "./icons/iconTrash.vue"
import LbrxMenuUnderline from "./LbrxMenuUnderline.vue";
import { LoadingOutlined } from '@ant-design/icons-vue';
import LbrxKeyboardPopup from "./LbrxKeyboardPopup.vue";
import { Empty } from 'ant-design-vue';
import { saleService } from "@/_services";
import webSocketService from '@/_services/webSocketService';

import { notification } from 'ant-design-vue';
import { currency } from "@/_helpers";

export default {
  name: "LbrxNumPad",
  components: {
    iconTrash,
    LbrxMenuUnderline,
    LoadingOutlined,
    LbrxKeyboardPopup
  },
  data() {
    return {
      simpleImage: Empty.PRESENTED_IMAGE_SIMPLE,
      amountChoices: [
        { id: 1, amount: 1 },
        { id: 1, amount: 2 },
        { id: 1, amount: 3 },
        { id: 1, amount: 4 },
        { id: 1, amount: 5 },
        { id: 1, amount: 6 },
        { id: 1, amount: 7 },
        { id: 1, amount: 8 },
        { id: 1, amount: 9 },
      ],
      filters: [
        {
          id: 1,
          name: this.$t('Pos.Cash'),
          is_default: 1,
          items: []
        },
        {
          id: 2,
          name: 'Other Modes',
          is_default: 0,
          items: [
            { name: "Card" },
            { name: "QR code" }
          ]
        },
      ],
      selectedFilter: 1,
      modalKeyboard: {
        active: false
      },
     
    };
  },
  props: {
    sale: {
      required: false,
      value: Object
    },
    amountToGive: {
      required: true,
      value: Number
    },
    paymentMethods: {
      required: false,
      value: Array
    },
    paymentMethodDefault: {
      required: false,
      value: Array
    },
    otherModes: {
      required: false,
      value: Array
    },
    selectedMethod: {
      required: false,
      value: Object
    },
    cashierMode: {
      required: false,
      value: Boolean,
      default: false
    },
    pincodeMode: {
      required: false,
      value: Boolean,
      default: false
    },
    formattedInput: {
      required: false,
      value: Boolean,
      default: false
    },
    noInput: {
      required: false,
      value: Boolean,
      default: false
    },
    textButtonConfirm: {
      required: false,
      value: String,
      default: ''
    },
    loading: {
      required: false,
      value: Boolean,
      default: false
    },
    posConfig: {
      required: true,
      value: Object
    },
    pos: {
      required: false,
      value: Object
    },
    currencyCode: {
      required: false,
      value: String
    }
  },
  computed: {
    decimalPrecision() {
      return this.posConfig.posConfig.decimalPrecision ? this.posConfig.posConfig.decimalPrecision : 3;
    },
    amountToGiveFormat() {
      return this.amountToGive.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.');
    },
    amountToGiveFormat2() {
      return this.amountToGive / 1000;
    },
    amountToGiveFixed() {
      return this.amountToGive.length != 0 ? (parseFloat(this.amountToGive) / 1000).toFixed(this.decimalPrecision) : (0).toFixed(this.decimalPrecision);
    },
    getSelectedStyle() {
      return {
        backgroundColor: JSON.parse(localStorage.getItem('themeTemplate')).primaryColor,
        color: '#fff'
      }
    },
    balance() {
      return (parseFloat(this.amountToGiveFormat2) - parseFloat(this.sale.total)).toFixed(this.decimalPrecision);
    }
  },
  
  methods: {
    formatAmount(amount, currencyCode, decimalPrecision) {
       const safeAmount = amount ?? 0;
      const safeCurrencyCode = currencyCode ?? ''; 
      const safeDecimalPrecision = decimalPrecision ?? 0; 
      return currency.formatAmount(safeAmount, safeCurrencyCode, safeDecimalPrecision);
      //return currency.formatAmount(amount, currencyCode, decimalPrecision);
    },
    addAmount(amount) {
      this.$emit("addAmount", amount);
    },
    deleteAmount() {
      this.$emit("deleteAmount");
    },
    selectFilter(item) {
      if (item == 1) {
        this.selectMethod(this.paymentMethodDefault[0]);
      }
      this.selectedFilter = item;
    },
    selectMethod(item) {
      this.$emit("selectMethod", item);
    },
    confirmAction() {
      this.$emit("confirmAction");
    },
    handleConfirmPayment(){
      if (this.balance >= 0 && this.sale.total && !this.loading) {
      this.confirmPayment();
    } else {
      console.warn('Cannot confirm payment: conditions not met');
    }
    },
    confirmPayment(payment = "") {
      console.log("confirm payement")
      if (this.posConfig.posConfig.generate_sale == 1) {
        this.$emit("toggleLoading", true);
        let payload = {
          cart_id: JSON.parse(localStorage.getItem("cart_info")).id.toString(),
          device_id: this.pos.id,
          user_id: JSON.parse(localStorage.getItem("user")).user.id
        }
        saleService.proceedSale(payload).then((res) => {
          localStorage.removeItem("cart_info");
          localStorage.setItem("sale", JSON.stringify(res.sale));
          this.showNotification(this.$t('Pos.ProceedSaleSuccess'));
          if (payment == "offer") {
            this.$emit("confirmPaymentOffer");
          } else if (payment == "no_cash") {
            this.$emit("confirmPaymentNoCash");
          } else {
            if (this.balance >= 0)
              this.$emit("confirmPayment", this.balance);
          }
        }).catch((error) => {
          console.log("error api : ", error);
        }).finally(() => {
          // this.$emit("toggleCartLoader", false);
        });
      } else {
        if (payment == "offer") {
          console.log("hhheree")
          this.$emit("confirmPaymentOffer");
        } else if (payment == "no_cash") {
          this.$emit("confirmPaymentNoCash");
        } else {
          if (this.balance >= 0)
            this.$emit("confirmPayment", this.balance);
        }
      }
    },
    
    getCode(event) {
      const inputValue = event.target.value;
      // this.$emit("searchCustomers", inputValue);
    },
    selectInput() {
      if (this.posConfig.posConfig.showKeyboard == 1) {
        this.modalKeyboard.active = true;
      }
    },
    closePopupKeyboard(text) {
      const inputValue = document.getElementById("numCode");
      inputValue.value = text;
      this.modalKeyboard.active = false;
    },
    showNotification(message) {
      notification.success({
        message: message,
        duration: 3, // 3s
        top: '80px',
        style: {
          color: 'white'
        }
      });
    },
    showErrorNotification(message) {
      notification.error({
        message: message,
        duration: 3, // 3s
        top: '80px',
        style: {
          backgroundColor: '#FFBFC98A',
          color: 'white'

        }
      });
    },
  }
};
</script>

<style scoped></style>